import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPhoneSquare} from '@fortawesome/free-solid-svg-icons';
import {faFacebookSquare, faLinkedin} from '@fortawesome/free-brands-svg-icons';

import contactImage from '../assets/contact.jpg';

const useStyles = makeStyles(theme => ({
  centerPadded: {
    padding: theme.spacing(2),
    textAlign: 'center',
    margin: 'auto',
  },
  centered: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    margin: 'auto',
    height: '100%',
  },
  stretchedCard: {
    height: '100%',
  },
  responsiveImage: {
    maxWidth: '100%',
  },
  contactButton: {
    padding: theme.spacing(1),
  }
}));

export default function Contact() {
  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={3} direction="row" justify="center" alignItems="stretch">
        <Grid item xs={12} lg={8}>
          <Card raised={true} className={classes.stretchedCard}>
            <CardContent className={classes.centerPadded}>
              <Box p={1}>
                <Typography variant="h4">
                  Connect With Us
                </Typography>
              </Box>
              <Box p={1}>
                <Link href="tel:+1-206-379-3069"
                  className={classes.contactButton}>
                  <FontAwesomeIcon icon={faPhoneSquare} size="5x"
                    color="#337ab7" />
                </Link>
                <Link href="https://www.facebook.com/Ready-To-Fly-Coaching-Consulting-470807700046124/" target="_blank" className={classes.contactButton}>
                  <FontAwesomeIcon icon={faFacebookSquare} size="5x"
                    color="#337ab7" />
                </Link>
                <Link href="https://www.linkedin.com/in/fiona-mckay-ready-124928108/" target="_blank" className={classes.contactButton}>
                  <FontAwesomeIcon icon={faLinkedin} size="5x" color="#337ab7" />
                </Link>
              </Box>
              <Box p={1}>
                <Typography variant="body1" component="p">
                Email:
                  <Link href="mailto:fiona@readytoflycoaching.com">
                  fiona@readytoflycoaching.com
                  </Link>
                </Typography>
                <Typography variant="body1" paragraph>
                Phone:
                  <Link href="tel:+1-206-379-3069">(206) 379-3069</Link>
                </Typography>
                <Typography variant="body1" paragraph>
                  Are you ready to dive into your tailor-made coaching program?
                  <br />Call us to schedule your complimentary consultation today.
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} lg={4}>
          <Card raised={true} className={classes.stretchedCard}>
            <Box className={classes.centered} p={1}>
              <img src={contactImage} alt="Contact Us" className={classes.responsiveImage} />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}