// eslint-disable-next-line no-unused-vars
import React, {useEffect} from 'react';
import {withRouter} from 'react-router-dom';

const ScrollToTop = ({ children, location: { pathname } }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children;
};

export default withRouter(ScrollToTop);