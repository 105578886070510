import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

import dkPartners1 from '../../assets/partners_dk.jpg';

const useStyles = makeStyles(theme => ({
  leftPadded: {
    padding: theme.spacing(2),
    textAlign: 'left',
    margin: 'auto',
  },
  centerPadded: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  centeredText: {
    textAlign: 'center',
  },
  stretchedCard: {
    height: '100%',
  },
  bioImageRight: {
    maxWidth: '300px',
    float: 'right',
    padding: theme.spacing(2),
  },
  subContent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  responsiveImage: {
    maxWidth: '100%',
  },
  centered: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    margin: 'auto',
    height: '100%',
  },
}));

export default function PartnersDK() {
  const classes = useStyles();

  return (
    <div>
      <Box p={2}>
        <Card raised={true} className={classes.stretchedCard}>
          <CardContent className={classes.leftPadded}>
            <Typography variant="h4" paragraph className={classes.centerPadded}>
              Dariush Khaleghi (DK)
            </Typography>
            <Typography variant="body1" paragraph component="div">
              <img src={dkPartners1} className={classes.bioImageRight} alt="Shauna" />
              A passion for leadership psychology, training, and diversity &amp; inclusion first brought Fiona and DK together as they launched a program to support aerospace businesses called <i>Diversity - It’s Good For Business</i>.  
            </Typography>
            <Typography variant="body1" paragraph component="div">
              As high energy and playful individuals set on making a change in the world they recognized that their unique superpowers could be a dynamic force in working with leaders to get behind the unspoken stories, assumptions and beliefs that limit their leadership abilities.
            </Typography>
            <Typography variant="body1" paragraph component="div">
              DK now uses the leadership skills he honed in military, corporate and government roles - including as the Director of the Washington State Human Rights Commission - and his knowledge of both business and psychology to inspire leaders through his work with Higher Education Institutions and his own company <i>Leadership Edge</i>.              
            </Typography>
            <Typography variant="body1" paragraph component="div">
              With over 30 years of experience, a Masters degree in Industrial-Organizational Psychology and an MBA, along with a series of complementary certifications, there are few people more qualified than DK to be a leader of leaders.
            </Typography>
          </CardContent>
        </Card>
        <Card raised={true} className={classes.subContent}>
          <CardContent className={classes.leftPadded}>
            <Typography variant="h5" paragraph className={classes.centeredText} component="div">
              <i>Fiona and DK are currently developing a program, <b>Emotional Capital for Leaders That Want to Leave a Legacy</b>. 
              <br /> More details will be announced at the end of 2019.</i>
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
}