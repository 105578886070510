import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/Card';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import allisonPartners1 from '../../assets/partners_allison.jpg';

const useStyles = makeStyles(theme => ({
  leftPadded: {
    padding: theme.spacing(2),
    textAlign: 'left',
    margin: 'auto',
  },
  centerPadded: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  centeredText: {
    textAlign: 'center',
  },
  stretchedCard: {
    height: '100%',
  },
  bioImageRight: {
    maxWidth: '300px',
    float: 'right',
    padding: theme.spacing(2),
  },
  subContent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  responsiveImage: {
    maxWidth: '100%',
  },
  centered: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    margin: 'auto',
    height: '100%',
  },
}));

export default function PartnersAllison() {
  const classes = useStyles();

  return (
    <div>
      <Box p={2}>
        <Card raised={true} className={classes.stretchedCard}>
          <CardContent className={classes.leftPadded}>
            <Typography variant="h4" paragraph className={classes.centerPadded}>
              Allison Kinnear
            </Typography>
            <Typography variant="body1" paragraph component="div">
              <img src={allisonPartners1} className={classes.bioImageRight} alt="Shauna" />
              Fiona first met Allison when they took a coaches training program together in Seattle, Washington. With their vivacious personalities, they were quickly drawn to one another and found that they had highly complementary skills and backgrounds.
            </Typography>
            <Typography variant="body1" paragraph component="div">
              Both went on to deepen their training through extensive training programs, Fiona through Accomplishment Coaching and Allison through Thrive Academy. As exceptionally transformed and high-achieving graduates of their work, Fiona and Allison were both invited to lead training programs that develop new coaches and leaders.
            </Typography>
            <Typography variant="body1" paragraph component="div">
              Allison now specializes in developing female leaders to grow in confidence and find their voices through her business Voice of Her Own and has worked with giants such as Microsoft and Amazon. Learn more about Allison 
              <Link href="https://voiceofherown.com/about/" target="_blank"> here</Link>.
            </Typography>
            <Typography variant="body1" paragraph component="div">
              Allison and Fiona now work together developing a series of Diversity &amp; Inclusion empowerment events. Their signature series, <i>Breaking Barriers to Shatter the Ceilings</i>, was launched in 2019.
            </Typography>
          </CardContent>
        </Card>
        <Card raised={true} className={classes.subContent}>
          <CardContent className={classes.leftPadded}>
            <Typography variant="h4" paragraph className={classes.centeredText}>
              Women Unite: Joining Forces to Break the Glass Ceiling
            </Typography>
            <Typography variant="h5" paragraph className={classes.centeredText} component="div">
              <i>Are you frustrated because it feels like women don’t always have each other’s backs?</i>
            </Typography>
            <Typography variant="body1" paragraph>
              Never has it been more important for women to build powerful &amp; supportive alliances. And yet, it can feel like our biggest struggle is with other women. It’s easy to be labeled as “steamrollers” or “people-pleasers” and yet polarizing creates more problems. Unchecked, we can get locked into a dynamic of mistrust, gossip and undermining - simply because we don’t understand each other. Join Fiona McKay, a reformed steamroller, and Allison Kinnear, a recovering people-pleaser, for a dynamic experience in which people-pleasers and steamrollers can become each other’s biggest advocates and allies.
            </Typography>
            <Typography variant="body1" paragraph component="div">
              In this workshop, you will learn:
              <ul>
                <li>The story of how a steamroller and a people-pleaser combined forces</li>
                <li>Our secrets to working effectively with someone who triggers you</li>
                <li>How vulnerability can become your secret weapon</li>
                <li>Why breaking down barriers is the solution to breaking the glass ceiling</li>
              </ul>
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
}