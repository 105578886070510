import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFeatherAlt} from '@fortawesome/free-solid-svg-icons';

import BioGrid from '../components/BioGrid';

const useStyles = makeStyles(theme => ({
  centerPadded: {
    padding: theme.spacing(2),
    textAlign: 'center',
    margin: 'auto',
  },
  centered: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    margin: 'auto',
    height: '100%',
  },
  subContent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  stretchedCard: {
    height: '100%',
  },
  redAvatar: {
    backgroundColor: theme.palette.primary.main,
  }
}));

const listPoints = [
  '1,000’s of hours of coaching, consulting, training & teaching.',
  'Certified by one of the world’s finest coach training programs.',
  'Accredited by the International Coaching Federation.',
  'Trained & mentored by Master Certified Coaches.',
  'Cutting edge coaching & leadership techniques.',
  'Leading, coaching and flying by example.',
];

export default function About() {
  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={3} direction="row" justify="center" alignItems="stretch">
        <Grid item xs={12} md={6}>
          <Card raised={true} className={classes.stretchedCard}>
            <Box className={classes.centered}>
              <List>
                {listPoints.map((value, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar className={classes.redAvatar}>
                        <FontAwesomeIcon icon={faFeatherAlt} size="1x" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography variant="h6">
                        {value}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card raised={true} className={classes.stretchedCard}>
            <CardContent className={classes.centerPadded}>
              <Typography variant="h3" component="p" className={classes.subContent}>
                Ready To Fly
              </Typography>
              <Typography variant="h5" component="p" className={classes.subContent}>
                <i>By shining a light on the greatness of the people and
                businesses that we work with, they tenaciously pursue the
                results they deserve…and even find enjoyment in the
                process!</i>
              </Typography>
              <Typography variant="body1" component="p" className={classes.subContent}>
                Ready to Fly delivers high-performance coaching,
                consulting and training, enabling people and
                businesses to soar to success. Our number one goal with
                our clients is to help them embrace their full potential
                through enhanced performance and transformed relationships.
                The key to unleashing this power is recognizing that it’s
                usually an inside job. Most people and businesses
                undervalue their abilities, living and creating from an
                inner narrative of limiting beliefs and self-sabotaging
                stories based on former experiences. The past cannot be changed, but our
                relationship to the present and the future can. By
                challenging and re-writing this dialogue people and businesses
                are able to achieve new levels of success in a way that is
                both sustainable and enjoyable.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <BioGrid />
    </div>
  );
}