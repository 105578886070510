import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

import shaunaPartners1 from '../../assets/partners_shauna.jpg';

const useStyles = makeStyles(theme => ({
  leftPadded: {
    padding: theme.spacing(2),
    textAlign: 'left',
    margin: 'auto',
  },
  centerPadded: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  centeredText: {
    textAlign: 'center',
  },
  stretchedCard: {
    height: '100%',
  },
  bioImageRight: {
    maxWidth: '300px',
    float: 'right',
    padding: theme.spacing(2),
  },
  subContent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  responsiveImage: {
    maxWidth: '100%',
  },
  centered: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    margin: 'auto',
    height: '100%',
  },
}));

export default function PartnersShauna() {
  const classes = useStyles();

  return (
    <div>
      <Box p={2}>
        <Card raised={true} className={classes.stretchedCard}>
          <CardContent className={classes.leftPadded}>
            <Typography variant="h4" paragraph className={classes.centerPadded}>
              Shauna Prince
            </Typography>
            <Typography variant="body1" paragraph component="div">
              <img src={shaunaPartners1} className={classes.bioImageRight} alt="Shauna" />
              As graduates of Accomplishment Coaching, Fiona and Shauna had the opportunity to experience personal transformation and expand their leadership side by side in one of the world’s finest and most rigorous coach training programs.  Both international transplants to the Pacific Northwest, their quirky accents led to a fast friendship.  
            </Typography>
            <Typography variant="body1" paragraph component="div">
              As driven high achievers with huge hearts they identified through the same struggles as they undertook Accomplishment Coaching’s 12-month coaching, leadership and personal transformation program.  By embracing ontology - the study of being human - they learned the process of integrating ‘being’ and ‘doing’ to achieve superior and lasting results.  Today they are part of the leadership team that delivers the Seattle program and hence they play a significant role in the training and development in the next generation of coaches. 
            </Typography>
            <Typography variant="body1" paragraph component="div">
              Shauna has a unique background with over 30 years of corporate and nonprofit marketing and leadership development experience in the US and Australia, most recently with Hewlett-Packard (HP).  In addition to her coaching certification Shauna is also recognized as a Master Neuro Linguistic Programming (NLP) Practitioner and has a Masters in Business Administration.
            </Typography>
            <Typography variant="body1" paragraph>
              Fiona and Shauna now collaborate to provide programs that integrate their knowledge of business, leadership and ontology to help develop high-performing teams for companies.            
            </Typography>
          </CardContent>
        </Card>
        <Card raised={true} className={classes.subContent}>
          <CardContent className={classes.leftPadded}>
            <Typography variant="h4" paragraph className={classes.centeredText}>
              High Performance Culture: Being ‘The Team’ Versus ‘The One’ 
            </Typography>
            <Typography variant="h5" paragraph className={classes.centeredText} component="div">
              <i>Do you have a team of rockstars that collectively can’t produce a winning album?</i>
            </Typography>
            <Typography variant="body1" paragraph>
              In a world of education, education, education, it’s easy for businesses to find people that excel in their field of expertise.  But while there are multiple forms of intelligence, only a limited number are usually considered when building a team.  
            </Typography>
            <Typography variant="body1" paragraph>
              This can lead to a culture of miscommunication, competing priorities, pent-up frustrations and mistrust.  It doesn’t have to be that way.  Through an appreciation of team dynamics, self-awareness and working styles a team can rise above the noise and operate as a well-oiled V12 engine.
            </Typography>
            <Typography variant="body1" paragraph component="div">
              In this program you will learn:
              <ul>
                <li>Key behaviors of a high performing team.</li>
                <li>The basis of your individual behavioral style, strengths and challenges</li>
                <li>How to optimise your superpowers and silence your inner saboteur</li>
                <li>Effective communication practices to use with you colleagues </li>
                <li>The breakthroughs that come from having difficult conversations - and how to have them!</li>
                <li>Tactics for avoiding team and individual burnout</li>
              </ul>
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
}