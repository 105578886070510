import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import PermPhoneMsgIcon from '@material-ui/icons/PermPhoneMsg';
import RoomServiceIcon from '@material-ui/icons/RoomService';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBullhorn, faPeopleCarry} from '@fortawesome/free-solid-svg-icons';


import {
  AboutLink,
  ContactLink,
  HomeLink,
  PartnersLink,
  ServicesLink,
  TestimonialsLink,
} from './SiteLinks';

const useStyles = makeStyles(theme => ({
  drawerWidth: {
    [theme.breakpoints.down('sm')]: {
      width: 280,
    },
    [theme.breakpoints.up('md')]: {
      width: 320,
    },
  },
}));

const NavDrawer = (props) => {

  const classes = useStyles();

  const {drawerOpen, setDrawerOpen} = props;

  const drawer = (
    <div>
      <Divider/>
      <List>
        <ListItem button key="Home" component={HomeLink}>
          <ListItemIcon><HomeIcon/></ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button key="About" component={AboutLink}>
          <ListItemIcon><InfoIcon/></ListItemIcon>
          <ListItemText primary="About"/>
        </ListItem>
        <ListItem button key="Services" component={ServicesLink}>
          <ListItemIcon><RoomServiceIcon/></ListItemIcon>
          <ListItemText primary="Services"/>
        </ListItem>
        <ListItem button key="Testimonials" component={TestimonialsLink}>
          <ListItemIcon><FontAwesomeIcon icon={faBullhorn} size="1x" /></ListItemIcon>
          <ListItemText primary="Testimonials"/>
        </ListItem>
        <ListItem button key="Partners" component={PartnersLink}>
          <ListItemIcon><FontAwesomeIcon icon={faPeopleCarry} size="1x" /></ListItemIcon>
          <ListItemText primary="Partners"/>
        </ListItem>
        <ListItem button key="Contact" component={ContactLink}>
          <ListItemIcon><PermPhoneMsgIcon/></ListItemIcon>
          <ListItemText primary="Contact"/>
        </ListItem>
      </List>
    </div>
  );

  return (
    <nav className={classes.drawer}>
      {/* The implementation can be swapped with js to avoid SEO duplication */}
      <Drawer
        variant="temporary"
        anchor={'right'}
        open={drawerOpen}
        onClose={() => setDrawerOpen(!drawerOpen)}
        classes={{
          paper: classes.drawerWidth,
        }}
      >
        {drawer}
      </Drawer>
    </nav>
  );

};

NavDrawer.propTypes = {
  drawerOpen: PropTypes.bool.isRequired,
  setDrawerOpen: PropTypes.func.isRequired,
};

export default NavDrawer;