import React, {forwardRef} from 'react';
import {Link} from 'react-router-dom';

export const HomeRoute = '/';
export const HomeLink = forwardRef((props, ref) => <Link to={HomeRoute} {...props} />);

export const AboutRoute = '/about';
export const AboutLink = forwardRef((props, ref) => <Link to={AboutRoute} {...props} />);

export const ServicesRoute = '/services';
export const ServicesLink = forwardRef((props, ref) => <Link to={ServicesRoute} {...props} />);

export const TestimonialsRoute = '/testimonials';
export const TestimonialsLink = forwardRef((props, ref) => <Link to={TestimonialsRoute} {...props} />);

export const PartnersRoute = '/partners';
export const PartnersLink = forwardRef((props, ref) => <Link to={PartnersRoute} {...props} />);

export const PaymentRoute = '/payment';
export const PaymentLink = forwardRef((props, ref) => <Link to={PaymentRoute} {...props} />);

export const ContactRoute = '/contact';
export const ContactLink = forwardRef((props, ref) => <Link to={ContactRoute} {...props} />);

export const BioFionaRoute = '/fiona';
export const BioFionaLink = forwardRef((props, ref) => <Link to={BioFionaRoute} {...props} />);

export const BioWillRoute = '/will';
export const BioWillLink = forwardRef((props, ref) => <Link to={BioWillRoute} {...props} />);

export const ServicesCoachingRoute = '/coaching';
export const ServicesCoachingLink = forwardRef((props, ref) => <Link to={ServicesCoachingRoute} {...props} />);

export const ServicesCorporateRoute = '/corporate';
export const ServicesCorporateLink = forwardRef((props, ref) => <Link to={ServicesCorporateRoute} {...props} />);

export const ServicesConsultingRoute = '/consulting';
export const ServicesConsultingLink = forwardRef((props, ref) => <Link to={ServicesConsultingRoute} {...props} />);

export const PartnersAllisonRoute = '/partners/allison';
export const PartnersAllisonLink = forwardRef((props, ref) => <Link to={PartnersAllisonRoute} {...props} />);

export const PartnersShaunaRoute = '/partners/shauna';
export const PartnersShaunaLink = forwardRef((props, ref) => <Link to={PartnersShaunaRoute} {...props} />);

export const PartnersDKRoute = '/partners/dariush';
export const PartnersDKLink = forwardRef((props, ref) => <Link to={PartnersDKRoute} {...props} />);
