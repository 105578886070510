import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import willBio1 from '../../assets/bio_will_1.jpg';
import willBio2 from '../../assets/bio_will_2.jpg';
import willBio3 from '../../assets/bio_will_3.jpg';
import willBio4 from '../../assets/bio_will_4.jpg';

const useStyles = makeStyles(theme => ({
  leftPadded: {
    padding: theme.spacing(2),
    textAlign: 'left',
    margin: 'auto',
  },
  centerPadded: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  stretchedCard: {
    height: '100%',
  },
  bioImageRight: {
    maxWidth: '100%',
    float: 'right',
    padding: theme.spacing(2),
  },
  subContent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  responsiveImage: {
    maxWidth: '100%',
  },
  centered: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    margin: 'auto',
    height: '100%',
  },
}));

export default function BioWill() {
  const classes = useStyles();

  return (
    <div>
      <Box p={2}>
        <Card raised={true} className={classes.stretchedCard}>
          <CardContent className={classes.leftPadded}>
            <Typography variant="h4" paragraph className={classes.centerPadded}>
              William Ready, MBA, BSc
            </Typography>
            <Typography variant="body1" paragraph component="div">
              <img src={willBio1} className={classes.bioImageRight} alt="Will Ready" />
              Will is a leader that brings compassion, wisdom, and joy all aspects of his life.  Described as a strong and patient leader, he is passionate about partnering with his clients to recognize and overcome their perceived barriers in life, enabling them to fulfill their dreams.
            </Typography>
            <Typography variant="body1" paragraph>
            The backbone of Will’s endeavors is based on his dedication to being of service to others, realized through coaching and teaching martial arts. As a sensei and a coach he is extremely proud to have played a role in helping people of all ages transform their life through new found confidence, dedication, and knowledge.
            </Typography>
            <Typography variant="body1" paragraph>
              Will's coaching is augmented by 15 years of experience in software architecture and design in the financial services sector. He has led high performing teams, managed multi-million dollar budgets, and earned a reputation of being able to accomplish what others perceive as impossible. He has a rare combination of being highly proficient technically while inspiring and leading others. This is reflected in both a B.S. is Computer Science as well as a Master's in Business Administration.
            </Typography>
            <Typography variant="body1" paragraph component="div">
              He has additional training and expertise in the following areas:
              <ul>
                <li>Leadership in Action</li>
                <li>Effectively Managing Change</li>
                <li>Public Speaking</li>
                <li>High Performing Teams</li>
                <li>Successfully Managing Your Manager</li>
                <li>Project Management</li>
                <li>Software Architecture and Design</li>
              </ul>
            </Typography>
            <Typography variant="body1" paragraph component="div">
              Will currently lives on Lake Marcel in Washington, with his wife Fiona and his dogs Winston and Dolly. He enjoys spending time traveling, snorkeling, paddle boarding, and relaxing by the lake.
            </Typography>
          </CardContent>
        </Card>
        <Grid container spacing={3} className={classes.subContent} direction="row" justify="center" alignItems="stretch">
          <Grid item xs={12} sm={6} md={4}>
            <Card raised={true} className={classes.stretchedCard}>
              <Box className={classes.centered} p={1}>
                <img src={willBio2} alt="Bio" className={classes.responsiveImage} />
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card raised={true} className={classes.stretchedCard}>
              <Box className={classes.centered} p={1}>
                <img src={willBio3} alt="Bio" className={classes.responsiveImage} />
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card raised={true} className={classes.stretchedCard}>
              <Box className={classes.centered} p={1}>
                <img src={willBio4} alt="Bio" className={classes.responsiveImage} />
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}