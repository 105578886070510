import React, {useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';

import {
  AboutLink,
  ContactLink,
  HomeLink,
  PartnersLink,
  ServicesLink,
  TestimonialsLink,
} from './SiteLinks';

import logo from '../assets/readytoflylogosmall.png';
import NavDrawer from './NavDrawer';

const useStyles = makeStyles(theme => ({
  pigLogo: {
    paddingRight: theme.spacing(1),
    maxWidth: '95px',
  },
  toolbarButtons: {
    marginLeft: 'auto',
  },
  reactiveShow: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  reactiveHide: {
    [theme.breakpoints.between('xs', 'md')]: {
      display: 'none',
    },
  },
  drawerButton: {
    border: 'solid',    
  }
}));

export default function NavBar() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const classes = useStyles();

  return (
    <AppBar position="sticky" color="secondary">
      <Toolbar>
        <img src={logo} className={classes.pigLogo} alt="ReadyToFly" />
        <Typography variant="h6" color="primary">
          High Performance Coaching &amp; Consulting
        </Typography>
        <div className={classes.toolbarButtons}>
          <div className={classes.reactiveHide}>
            <Button className={classes.appBarText} color="primary" component={HomeLink}>
              Home
            </Button>
            <Button className={classes.appBarText} color="primary" component={AboutLink}>
              About
            </Button>
            <Button className={classes.appBarText} color="primary" component={ServicesLink}>
              Services
            </Button>
            <Button className={classes.appBarText} color="primary" component={TestimonialsLink}>
              Testimonials
            </Button>
            <Button className={classes.appBarText} color="primary" component={PartnersLink}>
              Partners
            </Button>
            <Button className={classes.appBarText} color="primary" component={ContactLink}>
              Contact
            </Button>            
          </div>
          <div className={classes.reactiveShow}>
            <IconButton
              color="primary"
              aria-label="Open drawer"
              onClick={() => setDrawerOpen(!drawerOpen)}
              className={classes.drawerButton}>
              <MenuIcon/>
            </IconButton>
          </div>
          <div className={classes.reactiveHide}>
            <NavDrawer drawerOpen={drawerOpen}
              setDrawerOpen={setDrawerOpen} />
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
}