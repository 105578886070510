import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Container, CardContent } from '@material-ui/core';

import {ContactLink} from '../components/SiteLinks';
import SiteMapGrid from '../components/SiteMapGrid';

import fionaPlane from '../assets/fiona_plane.jpg';

const useStyles = makeStyles(theme => ({
  centerPadded: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.primary,
  },
  subContent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  centeredContent: {
    margin: 'auto',
    textAlign: 'center',
  },
  responsiveImage: {
    maxWidth: '100%',
  }
}));

export default function Home() {
  const classes = useStyles();

  return (
    <div>
      <Container>
        <Typography variant="h3" className={classes.centerPadded}>
          READY TO FLY COACHING &amp; CONSULTING
        </Typography>
        <Divider variant="middle" />
      </Container>
      <Container>
        <Typography variant="h5" className={classes.centerPadded}>
          <i>Turn your impossibles into your realities</i>
        </Typography>
        <Divider variant="middle" padding={2} />
      </Container>
      <Container className={classes.subContent}>
        <Card raised={true}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <img src={fionaPlane} alt="Fiona McKay" className={classes.responsiveImage} />
              </Grid>
              <Grid item xs={12} md={6} className={classes.centeredContent}>
                <Typography variant="h3" component="p" className={classes.subContent}>
                  Ready To Create
                  <br />Breakthrough Results?
                </Typography>
                <Typography variant="h5" component="p" className={classes.subContent}>
                  <i>Embrace the journey taken by countless
                  business leaders before you.</i>
                </Typography>
                <Button variant="contained" className={classes.subContent} component={ContactLink} color="primary">
                  Connect With Ready To Fly
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <SiteMapGrid />
      </Container>
    </div>
  );
}