import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFeatherAlt} from '@fortawesome/free-solid-svg-icons';

import servicesConsulting1 from '../../assets/services_consulting_1.jpg';
import servicesConsulting2 from '../../assets/services_consulting_2.jpg';
import servicesConsulting3 from '../../assets/services_consulting_3.jpg';
import servicesConsulting4 from '../../assets/services_consulting_4.jpg';

const useStyles = makeStyles(theme => ({
  centerPadded: {
    padding: theme.spacing(2),
    textAlign: 'center',
    margin: 'auto',
  },
  centered: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    margin: 'auto',
    height: '100%',
  },
  leftPadded: {
    padding: theme.spacing(2),
    textAlign: 'left',
    margin: 'auto',
  },
  subContent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  stretchedCard: {
    height: '100%',
  },
  redAvatar: {
    backgroundColor: theme.palette.primary.main,
  },
  responsiveImage: {
    maxWidth: '100%',
  },
}));

const aerospacePoints = [
  'Aerospace 101: Key Pillars for Engaging the Aerospace Industry',
  'Jet Engines 101: Doing Business with the Engine Manufacturers',
  'The Aerospace Sales Process: Not For the Faint Hearted!',
];

const smePoints = [
  'Project Management 101: Structures and Tools For Delivering Excellence',
  'Supply Chain Design 101: Tools for Optimizing Logistics and Costs',
  'Relationship Management 101: Enhancing Your Reputation with Your Customer',
  'Leadership 101: From Breakdown to Breakthrough - Principles for Sharpening Your Leadership & Engaging Your Team',
  'Events 101: Designing and Delivering Events that Impress  ',
];

const individualPoints = [
  'Business Basics: Knowledge and Tools for a Head Start',
  'Ready, Set, Grow: Establishing a Sales Pipeline  ',
];

export default function ServicesConsulting() {
  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={3} direction="row" justify="center" alignItems="stretch">
        <Grid item xs={12} md={6}>
          <Card raised={true} className={classes.stretchedCard}>
            <CardContent className={classes.centerPadded}>
              <Typography variant="h5" component="p" paragraph>
                <i>We get it, sometimes you just want to talk to the experts.  
                  <br/>At <b>Ready to Fly</b>, we have got you covered.</i> 
              </Typography>
              <Typography variant="h5" component="p" paragraph>
                Through extensive training, collective decades of experience in the corporate world, establishing our own businesses and running a non-profit, our team has some pretty awesome expertise.
              </Typography>
              <Typography variant="h5" component="p" paragraph>
                As global advisors to established industry consulting and expertise businesses including Gerson Lehrman Group, Guidepoint and Coleman, we have first-hand exposure to the types of inquiry and issues surfacing in the business world.  If you want advice from experts, a second opinion on a project you have established, or to train your team in key business knowledge and skills then we are able to offer our services in a range of areas.
              </Typography>
            </CardContent>    
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card raised={true} className={classes.stretchedCard}>
            <Box className={classes.centered} p={2}>
              <Typography variant="h3" component="p" className={classes.subContent} paragraph>
                Consulting
              </Typography>
              <img src={servicesConsulting1} alt="Consulting" className={classes.responsiveImage} />
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.subContent} direction="row" justify="center" alignItems="stretch">
        <Grid item xs={12} md={6}>
          <Card raised={true} className={classes.stretchedCard}>
            <Box className={classes.centered} p={2}>
              <img src={servicesConsulting2} alt="Coaching" className={classes.responsiveImage} />
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card raised={true} className={classes.stretchedCard}>
            <Box className={classes.centered} p={2}>
              <Typography variant="h5" className={classes.centerPadded} component="p">
                <i>For companies looking to enter or enhance their business in the aerospace market:</i>
              </Typography>
              <List className={classes.leftPadded}>
                {aerospacePoints.map((value, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar className={classes.redAvatar}>
                        <FontAwesomeIcon icon={faFeatherAlt} size="1x" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography variant="h6">
                        {value}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.subContent} direction="row" justify="center" alignItems="stretch">
        <Grid item xs={12} md={6}>
          <Card raised={true} className={classes.stretchedCard}>
            <Box className={classes.centered} p={2}>
              <Typography variant="h5" className={classes.centerPadded} component="p">
                <i>For small to medium sized businesses looking to upskill their teams and enable them to operate more effectively:</i>
              </Typography>
              <List className={classes.leftPadded}>
                {smePoints.map((value, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar className={classes.redAvatar}>
                        <FontAwesomeIcon icon={faFeatherAlt} size="1x" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography variant="h6">
                        {value}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card raised={true} className={classes.stretchedCard}>
            <Box className={classes.centered} p={2}>
              <img src={servicesConsulting3} alt="Coaching" className={classes.responsiveImage} />
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.subContent} direction="row" justify="center" alignItems="stretch">
        <Grid item xs={12} md={6}>
          <Card raised={true} className={classes.stretchedCard}>
            <Box className={classes.centered} p={2}>
              <img src={servicesConsulting4} alt="Coaching" className={classes.responsiveImage} />
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card raised={true} className={classes.stretchedCard}>
            <Box className={classes.centered} p={2}>
              <Typography variant="h5" className={classes.centerPadded} component="p">
                <i>For individuals and small groups in the process of establishing their own business:</i>
              </Typography>
              <List className={classes.leftPadded}>
                {individualPoints.map((value, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar className={classes.redAvatar}>
                        <FontAwesomeIcon icon={faFeatherAlt} size="1x" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography variant="h6">
                        {value}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}