import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import {
  ServicesCoachingLink,
  ServicesCorporateLink,
  ServicesConsultingLink,
} from '../components/SiteLinks';

import servicesCoaching from '../assets/services_coaching.jpg';
import servicesCorporate from '../assets/services_corporate.jpg';
import servicesConsulting from '../assets/services_consulting.jpg';

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
  },
  responsiveImage: {
    maxWidth: '100%',
  },
  centeredText: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
}));

export default function Services() {
  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={3} direction="row" justify="center" alignItems="stretch">
        <Grid item xs={12} sm={6} md={4}>
          <Card raised={true} className={classes.card}>
            <CardActionArea>
              <ServicesCoachingLink>
                <CardMedia component="img"
                  alt="Coaching 1:1"
                  className={classes.responsiveImage}
                  image={servicesCoaching}
                  title="Coaching" />
                <CardContent>
                  <Typography variant="h5" className={classes.centeredText} color="primary">
                    Coaching 1:1
                  </Typography>
                </CardContent>
              </ServicesCoachingLink>
            </CardActionArea>
          </Card>          
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card raised={true} className={classes.card}>
            <CardActionArea>
              <ServicesCorporateLink>
                <CardMedia component="img"
                  alt="Corporate Coaching and Training"
                  className={classes.responsiveImage}
                  image={servicesCorporate}
                  title="Corporate Coaching and Training" />
                <CardContent>
                  <Typography variant="h5" className={classes.centeredText} color="primary">
                    Corporate Coaching &amp; Training
                  </Typography>
                </CardContent>
              </ServicesCorporateLink>
            </CardActionArea>
          </Card>          
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card raised={true} className={classes.card}>
            <CardActionArea>
              <ServicesConsultingLink>
                <CardMedia component="img"
                  alt="Consulting"
                  className={classes.responsiveImage}
                  image={servicesConsulting}
                  title="Consulting" />
                <CardContent>
                  <Typography variant="h5" className={classes.centeredText} color="primary">
                    Consulting
                  </Typography>
                </CardContent>
              </ServicesConsultingLink>
            </CardActionArea>
          </Card>          
        </Grid>
      </Grid>
    </div>
  );
}