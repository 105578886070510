import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import fionaBio1 from '../../assets/bio_fiona_1.jpg';
import fionaBio2 from '../../assets/bio_fiona_2.jpg';
import fionaBio3 from '../../assets/bio_fiona_3.jpg';
import fionaBio4 from '../../assets/bio_fiona_4.jpg';

const useStyles = makeStyles(theme => ({
  leftPadded: {
    padding: theme.spacing(2),
    textAlign: 'left',
    margin: 'auto',
  },
  centerPadded: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  stretchedCard: {
    height: '100%',
  },
  bioImageRight: {
    maxWidth: '100%',
    float: 'right',
    padding: theme.spacing(2),
  },
  subContent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  responsiveImage: {
    maxWidth: '100%',
  },
  centered: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    margin: 'auto',
    height: '100%',
  },
}));

export default function BioFiona() {
  const classes = useStyles();

  return (
    <div>
      <Box p={2}>
        <Card raised={true} className={classes.stretchedCard}>
          <CardContent className={classes.leftPadded}>
            <Typography variant="h4" paragraph className={classes.centerPadded}>
              Fiona McKay, MA, MSc, PCC
            </Typography>
            <Typography variant="body1" paragraph component="div">
              <img src={fionaBio1} className={classes.bioImageRight} alt="Fiona McKay" />
              Fiona McKay-Ready is a Business, Executive and Badass Coach described by many as a powerhouse due to her passion, authenticity and playful nature.  As the founder of Ready to Fly LLC she enables people and businesses to soar to success.  Her ultimate commitment is to inspire and support her clients to achieve what they perceive to be ‘the impossible,’ finding the courage to pursue their goals and dreams, whatever their size.
            </Typography>
            <Typography variant="body1" paragraph>
              Fiona’s passion for transforming lives was ignited after graduating from Oxford University, when she spent two years teaching in inner-city schools as part of a social enterprise program. She then embarked on a career in aerospace at Rolls-Royce plc, where she played a key role on the board of Rolls-Royce Women, a world-class inclusive network established to strengthen women’s contribution and support in business.  
            </Typography>
            <Typography variant="body1" paragraph>
              Nominated for high potential programs early in her career, this enabled Fiona to work in various business functions, geographic locations and leadership positions, in addition to pursuing a Masters in Project Management while working.  A British native, Fiona relocated to Seattle to manage the relationship between Rolls-Royce and The Boeing Company before being headhunted by Parker Aerospace.  In both roles Fiona was the first female and youngest person ever to serve.  Throughout these experiences Fiona was a keen observer of human behavior, enabling her to distinguish the habits and qualities that empower people to stand out from the crowd and rise above the status quo – regardless of their background or circumstances.
            </Typography>
            <Typography variant="body1" paragraph component="div">
              For the last 18 months she has used the knowledge, skills and resilience from these experiences to fuel her work for a non-profit that supports small aerospace businesses. Although now working a limited schedule for them, to date she has increased revenues by more than 30%, recruited and trained a new CEO and doubled the size of their annual Women In Aerospace conference.
            </Typography>
            <Typography variant="body1" paragraph component="div">
              It was Fiona’s personal experience of being coached ignited her desire to create Ready To Fly. Within six months of entering the world of coaching her life was changed in a way that she never dreamed possible, giving her the courage to take a leap of faith in pursuing a new career and lifestyle. To date this has included walking away from a secure but unfulfilling six-figure job, setting up her own business, qualifying as a dance teacher and buying her dream ‘retirement’ home, a lake house that she intends to turn into a retreat space, decades earlier than planned.
            </Typography>
            <Typography variant="body1" paragraph>
              Since embracing her calling to be a professional coach and trainer Fiona has worked with a diverse group of business executives, aspiring leaders and entrepreneurs.  Her clients have described their coaching as a journey that creates significant shifts in their confidence and courage, enabling them to embrace new opportunities and ‘live out loud’ through the power of living, loving and working authentically. 
            </Typography>
            <Typography variant="body1" paragraph>
              In her spare time, she enjoys dance classes and exploring Seattle and the US with her husband, Will, and her rescue dogs, Winston and Dolly.
            </Typography>
          </CardContent>
        </Card>
        <Grid container spacing={3} className={classes.subContent} direction="row" justify="center" alignItems="stretch">
          <Grid item xs={12} sm={6} md={4}>
            <Card raised={true} className={classes.stretchedCard}>
              <Box className={classes.centered} p={1}>
                <img src={fionaBio2} alt="Bio" className={classes.responsiveImage} />
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card raised={true} className={classes.stretchedCard}>
              <Box className={classes.centered} p={1}>
                <img src={fionaBio3} alt="Bio" className={classes.responsiveImage} />
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card raised={true} className={classes.stretchedCard}>
              <Box className={classes.centered} p={1}>
                <img src={fionaBio4} alt="Bio" className={classes.responsiveImage} />
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}