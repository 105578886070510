import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import {
  BioFionaLink,
  BioWillLink,
} from '../components/SiteLinks';

import fionaHeadshot from '../assets/fiona_headshot.jpg';
import willHeadshot from '../assets/will_headshot.jpg';

const useStyles = makeStyles(theme => ({
  centeredText: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  subContent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  headshotCard: {
    height: '100%',
  },
  responsiveImage: {
    maxWidth: '100%',
  },
}));

export default function BioGrid() {
  const classes = useStyles();

  return (
    <Grid container spacing={3} className={classes.subContent} direction="row" justify="center" alignItems="stretch">
      <Grid item xs={12} sm={6}>
        <Card raised={true} className={classes.headshotCard}>
          <CardActionArea>
            <BioFionaLink>
              <CardMedia component="img"
                alt="Fiona McKay"
                className={classes.responsiveImage}
                image={fionaHeadshot}
                title="Fiona McKay" />
              <CardContent>
                <Typography variant="h5" className={classes.centeredText} color="primary">
                  Fiona McKay, MA, MSc, PCC
                </Typography>
              </CardContent>
            </BioFionaLink>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Card raised={true} className={classes.headshotCard}>
          <CardActionArea>
            <BioWillLink>
              <CardMedia component="img"
                alt="Will Ready"
                className={classes.responsiveImage}
                image={willHeadshot}
                title="Will Ready" />
              <CardContent>
                <Typography variant="h5" className={classes.centeredText} color="primary">
                  William Ready, MBA, BSc
                </Typography>
              </CardContent>
            </BioWillLink>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  );
}