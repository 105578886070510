import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFrown, faGrinStars, faFeatherAlt} from '@fortawesome/free-solid-svg-icons';

import {ContactLink} from '../../components/SiteLinks';

import servicesCoaching1 from '../../assets/services_coaching_1.jpg';
import servicesCoaching2 from '../../assets/readytoflylogo.png';

const useStyles = makeStyles(theme => ({
  centerPadded: {
    padding: theme.spacing(2),
    textAlign: 'center',
    margin: 'auto',
  },
  centered: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    margin: 'auto',
    height: '100%',
  },
  leftPadded: {
    padding: theme.spacing(2),
    textAlign: 'left',
    margin: 'auto',
  },
  subContent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  stretchedCard: {
    height: '100%',
  },
  redAvatar: {
    backgroundColor: theme.palette.primary.main,
  },
  responsiveImage: {
    maxWidth: '100%',
  },
}));

const sadPoints = [
  'Tired of feeling like you’re stuck in a box or going around in circles?',
  'Hiding the ‘real’ you?',
  'Frustrated with yourself and your colleagues?',
  'Spending too much time beating yourself up?',
  'Lacking in joy and contentment?',
  'Or you want to be a badass but got lost on the journey?',
];

const coachingPoints = [
  'Having the life and the impact that you want – both inside and outside of the workplace',
  'Seeing yourself through someone else’s eyes',
  'Understanding yourself from the inside out',
  'Shaking up your inner narrative',
  'Uncovering THE BIG GOALS you’ve stopped dreaming about',
  'Figuring out the obstacles in your pursuit of THE BIG GOALS',
  'Identifying a roadmap to achieving THE BIG GOALS', 
  'Developing strategies to navigate the potholes that inevitably show up along the way',
  'Enhancing your resilience to the naysayers',
  'Learning to fail with grace and recognize the lessons they offer in the journey to success',
];

const readyToFlyPoints = [
  'Uncovering the best of you',
  'Speaking truth to power',
  'Not buying into your stories',
  'Calling you out on your BS (with love!)',
  'Being a stand for your greatness',
  'Holding you accountable to the results you really want',
];

export default function ServicesCoaching() {
  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={3} direction="row" justify="center" alignItems="stretch">
        <Grid item xs={12} md={6}>
          <Card raised={true} className={classes.stretchedCard}>
            <Box className={classes.centered} p={2}>
              <List>
                {sadPoints.map((value, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar className={classes.redAvatar}>
                        <FontAwesomeIcon icon={faFrown} size="1x" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography variant="h6">
                        {value}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
              <Typography variant="h5" component="p" paragraph>
                <i>Then coaching could be just the thing you need.</i>
              </Typography>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card raised={true} className={classes.stretchedCard}>
            <Box className={classes.centered} p={2}>
              <img src={servicesCoaching1} alt="Coaching" className={classes.responsiveImage} />
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.subContent} direction="row" justify="center" alignItems="stretch">
        <Grid item xs={12} md={6}>
          <Card raised={true} className={classes.stretchedCard}>
          <CardContent className={classes.centerPadded}>
              <Typography variant="h3" component="p" className={classes.subContent} paragraph>
                Working With a Coach
              </Typography>
              <Typography variant="h5" component="p" className={classes.leftPadded} paragraph>
                Coaching is a great way to get some altitude and reflect on what’s happening in your life with someone that is highly committed to you achieving not only your goals but your dreams.  
              </Typography>
              <Typography variant="h5" component="p" className={classes.leftPadded} paragraph>
                A coach can support you by being a mirror in which you can powerfully and objectively look at your life.  From there you can design the personal and professional life that aligns with your values and your dreams.
              </Typography>
              <Typography variant="h5" component="p" className={classes.leftPadded} paragraph>
                A well trained and experienced ontological coach is able to listen on multiple levels, ask thought-provoking questions, make bold reflections, and keep you accountable to what you say you really want.      
              </Typography>
            </CardContent>    
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card raised={true} className={classes.stretchedCard}>
            <Box className={classes.centered} p={2}>
              <Typography variant="h5" component="p" paragraph>
                <i>The benefits of working with a coach include:</i>
              </Typography>
              <List>
                {coachingPoints.map((value, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar className={classes.redAvatar}>
                        <FontAwesomeIcon icon={faGrinStars} size="1x" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography variant="h6">
                        {value}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3} direction="row" justify="center" alignItems="stretch">
        <Grid item xs={12} md={6}>
          <Card raised={true} className={classes.stretchedCard}>
            <Box className={classes.centered} p={2}>
              <Typography variant="h5" component="p" paragraph>
                <i><b>Ready To Fly</b> coaches are committed to:</i>
              </Typography>
              <List>
                {readyToFlyPoints.map((value, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar className={classes.redAvatar}>
                        <FontAwesomeIcon icon={faFeatherAlt} size="1x" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography variant="h6">
                        {value}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
              <Typography variant="h5" component="div" className={classes.centerPadded} paragraph>
                <i><ContactLink color="primary">Contact us</ContactLink> today for a complimentary consultation to discover the options available to you.</i>
              </Typography>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card raised={true} className={classes.stretchedCard}>
            <Box className={classes.centered} p={2}>
              <img src={servicesCoaching2} alt="Coaching" className={classes.responsiveImage} />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}