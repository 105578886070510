import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFeatherAlt} from '@fortawesome/free-solid-svg-icons';

import servicesCorporate1 from '../../assets/services_corporate_1.jpg';

const useStyles = makeStyles(theme => ({
  centerPadded: {
    padding: theme.spacing(2),
    textAlign: 'center',
    margin: 'auto',
  },
  centeredText: {
    textAlign: 'center',
  },
  centered: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    margin: 'auto',
    height: '100%',
  },
  leftPadded: {
    padding: theme.spacing(2),
    textAlign: 'left',
    margin: 'auto',
  },
  subContent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  stretchedCard: {
    height: '100%',
  },
  redAvatar: {
    backgroundColor: theme.palette.primary.main,
  },
  responsiveImage: {
    maxWidth: '100%',
  },
}));

const portfolioPoints = [
  'Inspire and Empower: The Next Generation of Leadership',
  'Women Unite: Breaking Barriers to Shatter the Ceilings',
  'High Performance Culture: Being ‘The Team’ Versus ‘The One’ ',
];

const workshopPoints = [
  'Executive Presence',
  'Working Globally',
  'Effective Networking',
  'Confidence Masterclass',  
];

export default function ServicesCorporate() {
  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={3} direction="row" justify="center" alignItems="stretch">
        <Grid item xs={12} md={6}>
          <Card raised={true} className={classes.stretchedCard}>
            <Box className={classes.centered} p={2}>
              <Typography variant="h3" component="p" className={classes.centerPadded} paragraph>
                Corporate Coaching &amp; Training
              </Typography>
              <Typography variant="h5" component="p" className={classes.centeredText} paragraph>
                <i>When coaching and powerful training tools are combined, the impact on the workplace can be significant, creating lasting change.</i> 
              </Typography>
              <Typography variant="h5" className={classes.centerPadded} component="p" paragraph>
                Ready To Fly works with organizations to co-create workshops and training events, in addition to a portfolio of courses that are already designed and ready for take-off.
              </Typography>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card raised={true} className={classes.stretchedCard}>
            <Box className={classes.centered} p={2}>
              <img src={servicesCorporate1} alt="Corporate" className={classes.responsiveImage} />
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.subContent} direction="row" justify="center" alignItems="stretch">
        <Grid item xs={12} md={6}>
          <Card raised={true} className={classes.stretchedCard}>
            <Box className={classes.centered} p={2}>
              <Typography variant="h5" component="p" paragraph>
                <i>Our portfolio includes the following programs:</i>
              </Typography>
              <List>
                {portfolioPoints.map((value, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar className={classes.redAvatar}>
                        <FontAwesomeIcon icon={faFeatherAlt} size="1x" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography variant="h6">
                        {value}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card raised={true} className={classes.stretchedCard}>
            <Box className={classes.centered} p={2}>
              <Typography variant="h5" className={classes.centerPadded} component="p" paragraph>
                <i>Half and full-day workshops are also available on the following topics:</i>
              </Typography>
              <List className={classes.leftPadded}>
                {workshopPoints.map((value, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar className={classes.redAvatar}>
                        <FontAwesomeIcon icon={faFeatherAlt} size="1x" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography variant="h6">
                        {value}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}