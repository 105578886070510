/* eslint-disable max-len */
export const testimonials = [
  {
    testimonial: 'Fiona blends real life business experience and coaching know-how in a manner that brings deep insight and great tools from the get go. In the time we have worked together she has been instrumental in helping me understand my full capability and access a new level of leadership and enjoyment.',
    client: 'JE, President (Aerospace), Seattle WA',
  },
  {
    testimonial: 'As a small business owner, I always worked on my business and never took the time to build my relationships up.  Through our work together she has supported me in making myself a priority and helped me dramatically improve at home and at work – this has even had a positive effect on others!  I was amazed to see how much working on myself personally impacted my bottom line professionally.  I found that I was a more effective leader, being more disciplined and increasing my overall efficiency day to day.  She was always flexible, professional and – most of all – caring.  I can’t thank her enough for all the time and effort she put into our sessions.',
    client: 'CJ, Agency Owner (Insurance), Seattle WA',
  },
  {
    testimonial: 'I have been working with Fiona as an executive coach for 6 months, and during that time she has made an impact on all aspects of my life.  My relationship with my children has improved. My relationship with my partner has improved.  And, my relationship with my corporation has clearly improved with talk of an imminent promotion filling the windscreen.  More importantly, doubt and fear have been replaced by confidence and energy, and I am dreaming big dreams once again.  If you are searching for that best version of yourself, Fiona can help you find it.',
    client: 'JB, Director (Aerospace), Los Angeles CA',
  },
  {
    testimonial: 'Fiona McKay is a powerful driver of business growth!  Leading by example, exuding confidence and competency, Fiona has crafted the most ideal training methods and learning experiences for our team, taking our company to a significantly higher level of success.  A strategist on employee performance, Fiona brings a contagious excitement to the prospect of learning new skills, as she models, mentors, coaches and trains our team.  The sense of accomplishment and empowerment in our team has driven superior business growth for the organization. With our staff as the starting point, Fiona has led us through a journey of transformation with Training Programs, Team Building, Best Practices and Tools, lifting our employees to new levels of personal excellence and productivity in the process.',
    client: 'MJ, Emeritus Executive Director (Non-Profit), Seattle WA',
  },
  {
    testimonial: 'I highly recommend coaching with Fiona McKay for anyone seeking to find their best. Through her supportive and demanding style she helped me identify obstacles in my professional and personal life and develop strategies to overcome these challenges. I learned a tremendous amount from Fiona and am excited to implement what she has taught me.',
    client: 'DD, Business Owner & Medical Consultant (Healthcare), Seattle WA',
  },
  {
    testimonial: 'I\'m tremendously grateful for Fiona\'s coaching at a critical juncture in my career! She helped me build the courage to leap from my previous stable corporate job, prepare for what came next, and go after and land an amazing job opportunity and significant salary bump within 3 months. Fiona is perceptive, astute, encouraging, creative, funny, and challenging as a coach. She has a knack for bringing out the best in people&mdash;offering opportunities and paths you never thought of, helping you excel at your strengths, managing any pitfalls, and helping you experience greater joy. I highly recommend her, especially for anyone seeking support with achieving new goals!',
    client: 'SJ, Management Consultant (Professional Services), Seattle WA',
  },
  {
    testimonial: 'I have been a client of Fiona’s for about 5 months now and am giving her the highest recommendation I can. She has been wonderful to work with and sensitive to my unique needs as a mother, wife, and professional. She drills down to the essences of issues and challenges them head on. She listens and respects personal opinions while challenging beliefs at the same time. She works to empower the client to find the answers instead of spoon-feeding them. I have appreciated her willingness to be flexible and yet still keep the conversation moving forward. Fiona is attentive to details, observant, and always willing to work hard for the outcome. She also holds you accountable to your agreements and responsibilities.',
    client: 'BG - Mom, Wife & Educator (and former surgeon), Issaquah WA',
  },
  {
    testimonial: 'Like many, I have found it hard to reach out to anyone and “admit” I need support, but with Fiona, right from the very first conversation I felt at ease, knew she was very real and she has been very empathetic, compassionate and incredibly encouraging! In the few months I have been working with her, she has been helping me work through my inner critic and often realize that whatever emotion or obstacle that is working me up often has something more deep rooted, or even a completely different underlying cause.   As a mompreneur seeking balance between my wild ideas, taking care of family and my relationship with my husband, she is helping me find acceptance of myself, the ability to hone in on a path in my business, while helping me find ways to do so without feeling like I am sacrificing my family.',
    client: 'RM, Mompreneur (STEM), Scottsdale AZ',
  },
  {
    testimonial: 'From understanding how I can better manage stress, to working with me on improving my productivity, health and relationships, Fiona has made a real and positive difference in how I live my life. Her method involved getting me to define areas where I felt I could improve or had challenges, and then work out the solutions together. She then used her expertise to produce a framework to implement them. My coaching sessions were certainly enlightening and at the end of each one I felt empowered to make the right kind of changes to improve my life.',
    client: 'SP, Marketing Director (Aerospace), Reston VA',
  },
  {
    testimonial: 'I worked with Fiona after I\'d been made redundant just after the birth of my first baby. Although expected, events left me confused and wondering how to make sure my next move was the right one for me. Fiona asked the questions that I hadn\'t realised I needed someone to ask. She was never intrusive but encouraged me to challenge my ingrained thoughts and attitudes. I never would have had the guts to go for what I did without our discussions. I would definitely recommend Fiona\'s coaching&mdash;in fact, I have recommended her to my sister and a good friend. She is very approachable, clear, willing to share her experiences but never pushes her own opinion. Thank you so much Fiona! Life would be so easy if everyone had a Fiona along for the ride!',
    client: 'LP, Accountant (Pharmaceuticals), Manchester UK',
  },
  {
    testimonial: 'Fiona is a very gifted coach that not only stretches and challenges you for improvement, but she also encourages you and helps you make the most out of your natural strengths and abilities.  The decision to work with her was a wonderful investment in myself and my business.',
    client: 'TM, Vice President (Aerospace), Seattle WA',
  },
  {
    testimonial: 'I started working with Fiona at a time in my life when my career, health, and mindset were quite honestly all over the place!  Fiona helped me back on track by helping me to formulate and verbalize my goals, create small manageable steps to meet them, and to realize ways in which I am holding myself back.  As someone who is both a perfectionist and an introvert, it can be challenging for me to open up to people about my inner thoughts. Fiona has a great way about her – being simultaneously supportive and direct, which has helped me feel comfortable talking to her.  She is honest, open, and works hard to help each person be the best version of themselves that they can be.  If you’re looking to get out of your own way and to start moving towards success, happiness and self-confidence, Fiona can help!',
    client: 'DC, Business Owner, Yoga Instructor and Holistic Health Coach (Health & Wellbeing), Seattle WA',
  },
  {
    testimonial: 'Fiona\'s coaching has helped me blossom into a confident speaker, a leader of women, and an organized professional. My career has taken off since we have been working together. Opportunities have opened up, including things that I never dreamed would be available to me, and I am able to communicate my experience and ideas effectively.  I also go about my day as my best self, true to my essence and living authentically. If I sense old behaviors creeping into place, I know exactly what tool to use to get me back on track with confidence. I finally found me again!',
    client: 'JB, Business Development Manager (Aerospace), Kent WA',
  },
];
