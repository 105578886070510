import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import {
  AboutLink,
  PartnersLink,
  ServicesLink,
  TestimonialsLink,
} from '../components/SiteLinks';

import aboutImage from '../assets/about.jpg';
import servicesImage from '../assets/services.jpg';
import testimonialsImage from '../assets/testimonials.jpg';
import partnersImage from '../assets/partners.jpg';

const useStyles = makeStyles(theme => ({
  centeredText: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  subContent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export default function SiteMapGrid() {
  const classes = useStyles();

  return (
    <Grid container spacing={3} className={classes.subContent}>
      <Grid item xs={12} sm={6} md={3}>
        <Card raised={true}>
          <CardActionArea>
              <AboutLink>
                <CardMedia component="img"
                  alt="About"
                  className={classes.responsiveImage}
                  image={aboutImage}
                  title="About" />
                <CardContent>
                  <Typography variant="h5" className={classes.centeredText} color="primary">
                    About
                  </Typography>
                </CardContent>
              </AboutLink>
            </CardActionArea>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card raised={true}>
          <CardActionArea>
              <ServicesLink>
                <CardMedia component="img"
                  alt="Services"
                  className={classes.responsiveImage}
                  image={servicesImage}
                  title="Services" />
                <CardContent>
                  <Typography variant="h5" className={classes.centeredText} color="primary">
                    Services
                  </Typography>
                </CardContent>
              </ServicesLink>
            </CardActionArea>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card raised={true}>
          <CardActionArea>
              <TestimonialsLink>
                <CardMedia component="img"
                  alt="Testimonials"
                  className={classes.responsiveImage}
                  image={testimonialsImage}
                  title="Testimonials" />
                <CardContent>
                  <Typography variant="h5" className={classes.centeredText} color="primary">
                    Testimonials
                  </Typography>
                </CardContent>
              </TestimonialsLink>
            </CardActionArea>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card raised={true}>
          <CardActionArea>
              <PartnersLink>
                <CardMedia component="img"
                  alt="Partners"
                  className={classes.responsiveImage}
                  image={partnersImage}
                  title="Partners" />
                <CardContent>
                  <Typography variant="h5" className={classes.centeredText} color="primary">
                    Partners
                  </Typography>
                </CardContent>
              </PartnersLink>
            </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  );
}