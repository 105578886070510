import { red } from '@material-ui/core/colors';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

// A custom theme for this app
let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#8c0006',
    },
    secondary: {
      main: '#e7e7e7',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: [
      '"Cormorant Garamond"', 
      '-apple-system', 
      'BlinkMacSystemFont', 
      '"Segoe UI"', 
      '"Roboto"', 
      '"Oxygen"',
      '"Ubuntu"', 
      '"Cantarell"', 
      '"Fira Sans"', 
      '"Droid Sans"', 
      '"Helvetica Neue"',
      'sans-serif',
    ].join(','),  
  },
});

theme = responsiveFontSizes(theme);

export default theme;