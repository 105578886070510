import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import fionaAndAllison from '../assets/fiona_and_allison.jpg';

import PartnersGrid from '../components/PartnersGrid';

const useStyles = makeStyles(theme => ({
  centerPadded: {
    padding: theme.spacing(2),
    textAlign: 'center',
    margin: 'auto',
  },
  centered: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    margin: 'auto',
    height: '100%',
  },
  subContent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  card: {
    height: '100%',
  },
  responsiveImage: {
    maxWidth: '100%',
  },
  centeredText: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },

}));

export default function Partners() {
  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={3} direction="row" justify="center" alignItems="stretch">
        <Grid item xs={12} md={6}>
          <Card raised={true} className={classes.card}>
            <Box className={classes.centered} p={2}>
              <Typography variant="h5" component="p" className={classes.centeredText}>
                <i>At <b>Ready To Fly</b> we consider ‘better together’ to be one of our leading principles.</i>
              </Typography>
              <Typography variant="h5" component="p" className={classes.centeredText}>
                Fortunate to have trained alongside many outstanding leaders in the personal and professional development field, 
                we have established joint workshops and referral systems to ensure that our clients are receiving the best possible support.  
                Learn more about some of our leading partners below.              
              </Typography>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card raised={true} className={classes.card}>
            <Box className={classes.centered} p={1}>
              <img src={fionaAndAllison} alt="Fiona and Allison" className={classes.responsiveImage} />
            </Box>
          </Card>          
        </Grid>
      </Grid>
      <PartnersGrid />
    </div>
  );
}