import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';

import TestimonialsStepper from '../components/TestimonialsStepper';

import fionaAndGarth from '../assets/fiona_and_garth.jpg';

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
  },
  centered: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    margin: 'auto',
    height: '100%',
  },
  responsiveImage: {
    maxWidth: '100%',
  },
  centeredText: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
}));

export default function Testimonials() {
  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={3} direction="row" justify="center" alignItems="stretch">
        <Grid item xs={12} md={6}>
          <Card raised={true} className={classes.card}>
            <Box className={classes.centered} p={1}>
              <img src={fionaAndGarth} alt="Fiona and Garth" className={classes.responsiveImage} />
            </Box>
          </Card>          
        </Grid>
        <Grid item xs={12} md={6}>
          <TestimonialsStepper />
        </Grid>
      </Grid>
    </div>
  );
}