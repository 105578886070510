import React from 'react';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import ReactGA from 'react-ga';

import {
  AboutRoute,
  ContactRoute,
  BioFionaRoute,
  BioWillRoute,
  HomeRoute,
  PartnersRoute,
  PartnersAllisonRoute,
  PartnersDKRoute,
  PartnersShaunaRoute,
  ServicesRoute,
  ServicesCoachingRoute,
  ServicesConsultingRoute,
  ServicesCorporateRoute,
  TestimonialsRoute
} from './components/SiteLinks';

import Home from './pages/Home';
import About from './pages/About';
import BioFiona from './pages/bio/BioFiona';
import BioWill from './pages/bio/BioWill';
import Contact from './pages/Contact';
import Partners from './pages/Partners';
import PartnersAllison from './pages/partners/PartnersAllison';
import PartnersDK from './pages/partners/PartnersDK';
import PartnersShauna from './pages/partners/PartnersShauna';
import Services from './pages/Services';
import ServicesCoaching from './pages/services/ServicesCoaching';
import ServicesConsulting from './pages/services/ServicesConsulting';
import ServicesCorporate from './pages/services/ServicesCorporate';
import Testimonials from './pages/Testimonials';

import NavBar from './components/NavBar';
import ScrollToTop from './components/ScrollToTop';
import withTracker from './components/withTracker';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  pigLogo: {
    paddingRight: theme.spacing(1),
    maxWidth: '95px',
  },
  main: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(2),
    marginTop: 'auto',
    backgroundColor: 'white',
    textAlign: 'center',
  },
}));

ReactGA.initialize('UA-144586505-1');

export default function App() {
  const classes = useStyles();

  return (
    <Router>
      <div className={classes.root}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <NavBar />
        <ScrollToTop>
          <Container component="main" className={classes.main} maxWidth="xl">
            <Switch>
              <Route path={AboutRoute} component={withTracker(About)}/>          
              <Route path={BioFionaRoute} component={withTracker(BioFiona)}/>          
              <Route path={BioWillRoute} component={withTracker(BioWill)}/>          
              <Route path={ContactRoute} component={withTracker(Contact)}/>
              <Route path={PartnersAllisonRoute} component={withTracker(PartnersAllison)}/>          
              <Route path={PartnersDKRoute} component={withTracker(PartnersDK)}/>          
              <Route path={PartnersShaunaRoute} component={withTracker(PartnersShauna)}/>          
              <Route path={PartnersRoute} component={withTracker(Partners)}/>          
              <Route path={ServicesRoute} component={withTracker(Services)}/>          
              <Route path={ServicesCoachingRoute} component={withTracker(ServicesCoaching)}/>          
              <Route path={ServicesConsultingRoute} component={withTracker(ServicesConsulting)}/>          
              <Route path={ServicesCorporateRoute} component={withTracker(ServicesCorporate)}/>          
              <Route path={TestimonialsRoute} component={withTracker(Testimonials)}/>          
              <Route path={HomeRoute} component={withTracker(Home)}/>
            </Switch>
          </Container>
        </ScrollToTop>
        <footer className={classes.footer}>
          <Container maxWidth="sm">
            <Typography variant="body2">&copy; 2019 by ReadyToFly LLC</Typography>
          </Container>
        </footer>
      </div>
    </Router>
  );
}