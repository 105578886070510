import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import MobileStepper from '@material-ui/core/MobileStepper';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {testimonials} from './TestimonialText';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuoteLeft, faQuoteRight} from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles(theme => ({
  centered: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    margin: 'auto',
    height: '100%',
  },
  centeredText: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  card: {
    height: '100%',
  },
}));

export default function TestimonialsStepper() {
  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = testimonials.length;

  /**
   * @return {void} move activeStep forward
   */
  function handleNext() {
    setActiveStep((prevActiveStep) => 
      (prevActiveStep === maxSteps - 1) ? 0 : prevActiveStep + 1);
  }

  /**
   * @return {void} move activeStep backward
   */
  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  return (
      <Card raised={true} className={classes.card}>
        <Box p={2} className={classes.centered}>
          <Paper square={false} elevation={0} onClick={handleNext}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  <FontAwesomeIcon icon={faQuoteLeft} size="1x" />
                  &nbsp;{testimonials[activeStep].testimonial} &nbsp;
                  <FontAwesomeIcon icon={faQuoteRight} size="1x" />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" align="right">
                  - {testimonials[activeStep].client}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            className={classes.mobileStepper}
            nextButton={
              <Button size="small" onClick={handleNext}
                disabled={activeStep === maxSteps - 1}>Next
                {classes.direction === 'rtl' ?
                  <KeyboardArrowLeft /> :
                  <KeyboardArrowRight />
                }
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack}
                disabled={activeStep === 0}>
                {classes.direction === 'rtl' ?
                  <KeyboardArrowRight /> :
                  <KeyboardArrowLeft />
                }
                  Back
              </Button>
            }
          />
        </Box>
      </Card>
  );
}