import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import {
  PartnersAllisonLink,
  PartnersShaunaLink,
  PartnersDKLink,
} from '../components/SiteLinks';

import allisonHeadshot from '../assets/partners_allison.jpg';
import shaunaHeadshot from '../assets/partners_shauna.jpg';
import dkHeadshot from '../assets/partners_dk.jpg';

const useStyles = makeStyles(theme => ({
  centeredText: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  subContent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  headshotCard: {
    height: '100%',
  },
  responsiveImage: {
    maxWidth: '100%',
  },
}));

export default function PartnersGrid() {
  const classes = useStyles();

  return (
    <Grid container spacing={3} className={classes.subContent} direction="row" justify="center" alignItems="stretch">
      <Grid item xs={12} sm={6} md={4}>
        <Card raised={true} className={classes.headshotCard}>
          <CardActionArea>
            <PartnersShaunaLink>
              <CardMedia component="img"
                alt="Shauna Prince"
                className={classes.responsiveImage}
                image={shaunaHeadshot}
                title="Shauna Prince" />
              <CardContent>
                <Typography variant="h5" className={classes.centeredText} color="primary">
                  Shauna Prince
                </Typography>
              </CardContent>
            </PartnersShaunaLink>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card raised={true} className={classes.headshotCard}>
          <CardActionArea>
            <PartnersAllisonLink>
              <CardMedia component="img"
                alt="Allison Kinnear"
                className={classes.responsiveImage}
                image={allisonHeadshot}
                title="Allison Kinnear" />
              <CardContent>
                <Typography variant="h5" className={classes.centeredText} color="primary">
                  Allison Kinnear
                </Typography>
              </CardContent>
            </PartnersAllisonLink>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card raised={true} className={classes.headshotCard}>
          <CardActionArea>
            <PartnersDKLink>
              <CardMedia component="img"
                alt="Dariush Khaleghi"
                className={classes.responsiveImage}
                image={dkHeadshot}
                title="Dariush Khaleghi" />
              <CardContent>
                <Typography variant="h5" className={classes.centeredText} color="primary">
                  Dariush Khaleghi
                </Typography>
              </CardContent>
            </PartnersDKLink>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  );
}